var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3 pb-3"},[_c('div',{staticClass:"mt-3 overflow-auto mh--97 mh-xl--97"},[(_vm.isLoading)?_c('div',{staticClass:"text-center py-4"},[_c('b-spinner',{attrs:{"label":"Spinning"}})],1):_c('div',[(_vm.galleries.length)?_c('div',_vm._l((_vm.galleries),function(gallery,index){return _c('div',{key:index},[(
              gallery.is_html_content === 0 &&
                gallery.is_deleted_by_author !== 1
            )?[_c('div',{staticClass:"border-bottom border-secondary pb-3 mb-2"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center mb-1"},[_c('div',{staticClass:"w--6 h--6 rounded-pill overflow-hidden me-1"},[_c('img',{staticClass:"img-cover",attrs:{"src":gallery.sender.user_profile.avatar
                          ? gallery.sender.user_profile.avatar
                          : _vm.avatarDefault,"alt":""}})]),_c('span',{staticClass:"fwb fs-12 fs-xl-15"},[_vm._v(_vm._s(gallery.sender.name))]),_c('span',{staticClass:"ms-1 fs-12 fs-xl-15"},[_vm._v(_vm._s(_vm.moment(gallery.created_at).format('YYYY/MM/DD HH:mm')))])]),(gallery.is_can_delete)?_c('div',{staticClass:"dropdown w--7 h--7 flex-center"},[_c('span',{staticClass:"far fa-ellipsis-h cursor-pointer fs-18 border-none",attrs:{"id":'dropdownLstFunction' + gallery.id,"data-bs-toggle":"dropdown","aria-expanded":"false"}}),_c('ul',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":'dropdownLstFunction' + gallery.id}},[_c('li',[_c('div',{staticClass:"dropdown-item py-1 ps-2 cursor-pointer",on:{"click":function($event){return _vm.openModalConfirmDelete(gallery)}}},[_vm._v(" "+_vm._s(_vm.$t('chat.gallery.dropdown_btn.delete'))+" ")])])])]):_vm._e()]),_c('div',{staticClass:"d-block"},_vm._l((_vm.hanldeUnknownArray(gallery.file)),function(file,index){return _c('div',{key:index},[(
                      _vm.getFileFormat(file.file_extension) ===
                        _vm.eFileFormat.IMAGE && _vm.displayThumbnail
                    )?_c('img',{staticClass:"rounded mw--60 mh--60",attrs:{"src":file.small_path,"alt":"gallery"}}):_c('div',{staticClass:"d-flex align-items-center text-primary"},[_c('div',{staticClass:"d-flex flex-center"},[_c('i',{class:("fs-xl-30 fs-25 py-2 " + (_vm.getFormatIconClass(
                            file.file_extension
                          )))})]),_c('div',{staticClass:"px-2 text-break"},[_c('span',{staticClass:"text-line-clamp-1 cursor-pointer",attrs:{"title":file.file_name}},[_vm._v(" "+_vm._s(_vm.handleLongText(file.file_name, 40).text)+" ")])])]),_c('div',{staticClass:"py-1"},[_c('button',{staticClass:"btn btn-outline-secondary-deep px-4 py-0",on:{"click":function($event){return _vm.downloadFile(file)}}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_download'))+" ")])])])}),0)]),_c('ModalConfirm',{attrs:{"id":("modal-confirm-chat-gallery-" + (gallery.id)),"confirmMess":_vm.modalMess},on:{"yes":function($event){return _vm.deleteGallery(gallery)}}})]:_vm._e()],2)}),0):_c('div',{staticClass:"text-secondary text-center py-4"},[_vm._v(" "+_vm._s(_vm.$t('common.message.no_data'))+" ")]),_c('div',{staticClass:"d-flex justify-content-center flex-1 mb-5"},[(_vm.page < _vm.maxPage)?_c('button',{staticClass:"btn btn-outline-secondary-deep",on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.$t('common.btn.btn_see_more'))+" ")]):_vm._e()])])]),_c('ModalSuccess',{attrs:{"id":'modal-success-chat-gallery',"successMess":_vm.modalMess}}),_c('ModalError',{attrs:{"id":'modal-error-chat-gallery',"errorMess":_vm.modalMess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }